import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { RoutedNavigationLink } from '../RoutedNavigationLink';
import { ListIcon } from './ListIcon';
import { CancelIcon } from './CancelIcon';
import ROUTES from '../../../routes';
import { PictorialCloselinkLogo } from '../PictorialCloselinkLogo';
import { Box, Flex, get, NavigationLink } from '../../system';

const Wrapper = styled.nav`
    position: fixed;
    background: ${get('colors.bold-blueish.10')};
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
`;

const NavigationList = styled.nav`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin-top: ${get('space.15')}px;

    * {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const LogoIconWrapper = props => (
    <Flex
        width="90%"
        margin="20px auto"
        justifyContent="space-between"
        alignItems="center"
        {...props}
    />
);

const MenuContent = ({ items, onClose }) => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <LogoIconWrapper>
                <Link to={ROUTES.INDEX}>
                    <PictorialCloselinkLogo />
                </Link>
                <CancelIcon height={40} width={40} onClick={onClose} />
            </LogoIconWrapper>
            <NavigationList>
                {items.map(item => (
                    <RoutedNavigationLink
                        fontSize={5}
                        key={item.href}
                        to={item.href}
                    >
                        {item.text}
                    </RoutedNavigationLink>
                ))}

                <NavigationLink fontSize={5} href={ROUTES.APP}>
                    {t('header.navigation.login')}
                </NavigationLink>

                <RoutedNavigationLink fontSize={5} to={ROUTES.CONTACT}>
                    {t('header.navigation.contact')}
                </RoutedNavigationLink>
            </NavigationList>
        </Wrapper>
    );
};

MenuContent.propTypes = {
    onClose: PropTypes.func,
    items: PropTypes.array
};

const Header = props => (
    <Box
        as="header"
        position="fixed"
        top={0}
        zIndex={2}
        backgroundColor="white"
        width="100%"
        {...props}
    />
);

export const MobileNavigation = () => {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    const items = [
        {
            text: t('header.navigation.purchaser'),
            href: ROUTES.PURCHASER
        },
        {
            text: t('header.navigation.supplier'),
            href: ROUTES.SUPPLIER
        },
        {
            text: t('header.navigation.about'),
            href: ROUTES.ABOUT
        },
        {
            text: t('header.navigation.news'),
            href: ROUTES.NEWS
        }
    ];

    if (open) {
        return (
            <Header>
                <MenuContent
                    onClose={() => setOpen(prevOpen => !prevOpen)}
                    items={items}
                />
            </Header>
        );
    }

    return (
        <Header>
            <LogoIconWrapper>
                <Link to={ROUTES.INDEX}>
                    <PictorialCloselinkLogo />
                </Link>
                <ListIcon
                    height={40}
                    width={40}
                    onClick={() => setOpen(prevOpen => !prevOpen)}
                />
            </LogoIconWrapper>
        </Header>
    );
};
