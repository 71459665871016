export default {
    header: {
        navigation: {
            purchaser: 'Purchaser',
            supplier: 'Supplier',
            about: 'About',
            news: 'News',
            login: 'Login',
            contact: 'Get in contact',
            company: 'Company'
        }
    },
    index: {
        seo: {
            title: 'Home'
        },
        header: {
            headline: 'A procurement platform that empowers the experts',
            subHeadline:
                'Closelink is a <strong>category management</strong> tool tailored to the sourcing and procurement of <strong>bunker fuels and marine lubricants</strong>.',
            quote:
                'Best-in-class companies combine a rigid strategic sourcing approach based on comprehensive <strong>category strategies</strong>.',
            quoteCaption:
                '“Jumpstart to Digital Procurement", Springer 2020 Wolfgang Schnellbächer & Daniel Weise,<br/> Managing Director & Partner at Boston Consulting Group'
        },
        benefitsHeadline: 'How Closelink helps to improve your procurement',
        benefits: [
            {
                headline: 'Category management',
                description:
                    'Category management in procurement enables significant cost savings and acts as efficiency booster. Closelink helps you to minimize manual work to become more professional and to reduce vessel spend.',
                bulletItems: [
                    'Integrate your stock reporting and consumption data',
                    'Integrate vessel schedules and next ports of call',
                    'Define key ports to concentrate volumes in preferable locations',
                    'Integrate contracts and manage your suppliers'
                ]
            },
            {
                headline: 'Demand planning',
                description:
                    'To optimize decisions Closelink helps you to find the best available options based on your vessel’s upcoming voyage and resulting individual demand.',
                bulletItems: [
                    'Monitor the demand and specific basics of your fleet',
                    'Receive automated demand alerts',
                    'Compare options and optimize timing decisions',
                    'Be in full control'
                ]
            },
            {
                headline: 'Smart ordering',
                description:
                    'The ordering process in Closelink is tailored to support each specific category, enabling you to effectively tackle its specific challenge. At the same time, the communication with your suppliers is being standardized within each category.',
                bulletItems: [
                    'Compare your order options in a mouse click',
                    'Apply our integrated approval process',
                    'Benefit from in-tool communication',
                    'Integrate with your internal systems'
                ]
            }
        ],
        purchaserFeature: {
            headline: 'You’re a purchaser?',
            description:
                'It doesn’t matter if you’re an owner or third-party manager. A local procurement team or global center of expertise. We provide you with the most simple and frictionless solution for your daily operations. <br /> <br />',
            linkText: 'Learn more'
        },
        supplierFeature: {
            headline: 'You’re a supplier?',
            description:
                'Are you a manufacturer with a global sales force, regional distributor with a unique selling point or global trader with a broad partner network? Let’s talk about your needs. Follow the path to generate additional business and smoothen your process.',
            linkText: 'Learn more'
        },
        facts: {
            headline: 'Join our network',
            subHeadline:
                'This is just the beginning. Choose Closelink to transform your daily operations.',
            items: [
                {
                    headline: '400',
                    subHeadline: 'Registered Vessels'
                },
                {
                    headline: '81',
                    subHeadline: 'Registered Suppliers'
                },
                {
                    headline: '9.0M',
                    subHeadline: 'Purchased Volume (in liter)'
                }
            ]
        },
        partners: {
            headline: 'Trusted by several partners'
        },
        testimonials: [
            {
                quote:
                    '“To us as vessel owners, every manager who uses a tool like Closelink demonstrates a clear willingness to work as efficiently, progressively and transparent as possible. It is exactly the step we need to take going forward.”',
                name: 'Jan Wolff',
                jobTitle: 'Partner at OKEE Maritime'
            },
            {
                quote:
                    '“As clearly demonstrated, the platform offers administrative and reporting benefits that support better planning of lubricant supplies across our entire fleet. If used properly, this optimized planning enables tangible cost savings for us and our customers.”',
                name: 'Michael Eden',
                jobTitle: 'Managing Director at Marlow Ship Management'
            }
        ],
        featuredTestimonial: {
            quote:
                'The best managers are familiar with <strong>digital technologies</strong> and able to use them <strong>in the specific context of their category</strong> to create value.',
            subHeadline:
                '“Delivering on Digital Procurement’s Promise”, Publication on www.bcg.com in 2018, by Martin Högel et al.',
            cta: 'Schedule a demo'
        },
        video: {
            title: 'What is Category Management?',
            description:
                'Watch this video to learn more about category management in general or some other text that describes the video.'
        }
    },
    career: {
        seo: {
            title: 'Career',
            description:
                'Excited to learn a lot, achieve personal growth and help shape the future of the maritime industry? We cannot wait to hear from you!'
        },
        header: {
            introText:
                'Be part of our team and share your ideas with the world.'
        },
        intro: {
            headline: 'Shape the future.',
            description: [
                'Closelink is becoming the leading technology platform in the maritime industry. We help our customers make the right decisions and improve their planning and cost spending. And we would love you to be part of this journey! Join a group of talented and experienced engineers, designers, product owners and sales managers.',
                'We are a group of people who love creating great products. All of us have already worked at large scale companies and now use this experience to transform a whole industry. All the challenges ahead make the every-day life exciting and fun!',
                'Never heard of marine lubricants before? Many of us have not either before joining, no problem! Now is the time to learn more about an industry, take ownership and be part of something exciting in the making!'
            ],
            aboutPageLink: 'Learn more'
        },
        posts: {
            headline: 'Open positions',
            description:
                'Excited to learn a lot, achieve personal growth and help shape the future of the maritime industry? We cannot wait to hear from you!'
        },
        jobPost: {
            applyButton: 'Apply Now'
        }
    },
    about: {
        seo: {
            title: 'About'
        },
        visionStatement: {
            headline: 'Our vision',
            description: 'Be the leading source for major ship supplies.'
        },
        coreValues: {
            headline: 'Core Values',
            description:
                'We are working on a common goal and share a bigger purpose. Our core values are fostering an everlasting, fruitful and motivating place of work.'
        },
        header: {
            introText:
                'We are Closelink. <br /> A technology company in the maritime industry.'
        },
        missionStatement: {
            headline: 'Our mission',
            description:
                'We strive to provide a platform to the shipping industry for business-to-business commerce that provides procurement teams with the best possible market transparency and the highest degree of efficiency in a perfectly user-centric environment. Our objective is to establish a global network of customers that suppliers are keen to service and deliver to continuously.'
        },
        team: {
            headline: 'The faces of Closelink',
            description:
                'We’re a team of technologists, creative problem solvers and business thinkers seeking to make an impact on the shipping industry. '
        },
        gallery: {
            all: 'All',
            management: 'Management',
            customerSuccess: 'Customer Success',
            engineering: 'Engineering',
            finance: 'Finance',
            product: 'Product',
            sales: 'Commercial'
        },
        values: [
            {
                headline: 'Lead by example',
                description:
                    'We inspire each other to exceed our potential by demonstration, encouragement and the freedom to learn from mistakes.'
            },
            {
                headline: 'Stay hungry to perform',
                description:
                    'We aim to deliver extraordinary achievements, work with passion and act with persistence.'
            },
            {
                headline: 'Take ownership',
                description:
                    'We face challenges with determination, take responsibility for our actions and aim to deliver valuable outcomes in all our endeavours.'
            },
            {
                headline: 'Embrace diversity',
                description:
                    'We embrace different personalities, strengths, ideas and cultural backgrounds to encourage pluralism in debate and opinion.'
            },
            {
                headline: 'Strive for personal development',
                description:
                    'We seek new challenges to push ourselves beyond personal boundaries and to expand our experience and capabilities.'
            },
            {
                headline: 'Motivate and inspire',
                description:
                    'We value a working culture that promotes flexibility, creativity and joy.'
            },
            {
                headline: 'Treat each other with respect',
                description:
                    'We treat each other with respect to establish a safe, supportive and productive work environment.'
            }
        ],
        timelineEvents: [
            {
                date: 'September 2016',
                name: 'Foundation'
            },
            {
                date: 'January 2017',
                name: 'Launch & First Customer '
            },
            {
                date: 'October 2017',
                name: 'Pre-Seed Round'
            },
            {
                date: 'May 2018',
                name: 'PitchBlue Winner'
            },
            {
                date: 'June 2018',
                name: 'InnoRampUp Investment'
            },
            {
                date: 'December 2018',
                name: 'NLA Investment'
            },
            {
                date: 'March 2019',
                name: 'Seed Round'
            }
        ],
        testimonial: {
            quote:
                '“While innovators see opportunity in change, they must also make that change happen. The Closelink founders understand this, so instead of attempting to change the maritime world as a whole, they specifically chose to implement change in a particular field: marine-lubricant procurement, where they show a very strong advantage and offer tangible benefits.”',
            name: 'Chye Poh Chua',
            jobTitle:
                'Venturist for maritime digitalisation, inclusivity & sustainability'
        }
    },
    security: {
        seo: {
            title: 'Security'
        },
        header: {
            headline: 'We got your back.',
            subHeadline:
                'Cyber Security is a very important topic that we consider seriously. From password security to database encryption, we do our best to protect your data.'
        },
        details: [
            {
                headline: 'Access Control',
                text:
                    'Our system is tailored with the customer in mind. All data processed as well as all account accesses are independent and individually assigned to each customer. Each account can be controlled by predefined roles with different access rights.  Each individual user access is protected with a distinct & personalised password which can be changed anytime and only by the user.'
            },
            {
                headline: 'Encryption',
                text:
                    'All customer related data is saved in our database, which is protected using the AES256 encryption standard. Additionally, every communication within our system e.g. between our database and backend or between the customer and our API is SSL/TLS encrypted.'
            },
            {
                headline: 'Infrastructure',
                text:
                    'Our entire system is isolated and firewall protected. Access is only possible through our website, which is using HTTPS and is secured by the newest security certificates. Your data is treated with great care. Therefore our database is physically hosted and located in Frankfurt, Germany by Amazon Web Services (AWS). Scaling our system and your operations is no big deal as we are using a flexible and reliable infrastructure.'
            }
        ],
        qa: {
            blocks: [
                {
                    headline: 'Is my data shared with third parties?',
                    text:
                        'No. Each customer account is treated separately. There are various security measurements strictly preventing third party access. Admin rights to access our system and database is only provided to our leading IT team and to no one outside our company.'
                },
                {
                    headline: 'Is Closelink GDPR compliant?',
                    text:
                        'Yes. We are obligated to match the General Data Protection Regulation (Datenschutzgrundverordnung/DSGVO in Germany) requirements. GDPR defines how personal data is stored, secured and used within our system. Already we try to keep personal data to a minimum and we would never do anything to put your data and its security at risk.'
                }
            ]
        },
        featuredQuote: {
            quote:
                '“Data protection is vital for our credibility and reliability. That’s why we are constanty working on security improvements for our customers.”',
            employeeKey: 'tobias-schumacher'
        },
        downloadButton: 'Download security booklet',
        standards: 'Other companies are using the same security standards'
    },
    supplier: {
        seo: {
            title: 'Supplier'
        },
        header: {
            headline: 'Create new business opportunities.',
            subHeadline:
                'Stay ahead in a changing market. We help you to master the platform business.'
        },
        businessChangeHeadline: 'Nature of business is changing',
        benefitHeadline: 'Your benefits',
        challenges: [
            {
                description:
                    'The business of tomorrow will differ fundamentally to what we were used to in the past. The need for more transparency and compliance is greater than ever before.'
            },
            {
                description:
                    'Customers focus on decreasing costs while ensuring standard quality and sufficient flexibility to operate. They combine purchasing power through cooperations and M&As.'
            },
            {
                description:
                    'Trading areas are quickly changing, as well, and customers are required to remain responsive and adapt while maintaining a high degree of cost control.'
            },
            {
                headline: 'Get the attention',
                description:
                    'Develop a unique proposition by exposing your product availability, pricing and specifications to the customer. We help you to extend your reach and shape awareness.'
            },
            {
                headline: 'Grab the opportunity',
                description:
                    'We grant you instant access to a wide range of business opportunities. Be one step ahead and learn how to stand out of the crowd.'
            },
            {
                headline: 'Our service, your benefit',
                description:
                    'We are not an active player in the purchasing and selling of marine lubricants or bunker fuels. We provide the market with a unique technical platform that everyone can use and benefit from.'
            }
        ],
        benefits: [
            {
                headline: 'Reduced short-term supplies',
                description:
                    'Emergency requests inflict enormous pressure on the supply chain. We gather data to predict future demand and reduce the risk of unplanned demand.'
            },
            {
                headline: 'Streamlined processes',
                description:
                    'We offer a seamless and fully integrable solution. We collaborate with existing system provider to allow aligned processes and automated data flow.'
            },
            {
                headline: 'Compliant interactions',
                description:
                    'We assure transparent and comprehensible processes to guarantee safe and reliable interactions between customer and supplier.'
            }
        ],
        pricing: {
            headline: 'Our prices for lubricant suppliers',
            subHeadline: 'Costs occur only for newly generated business.',
            box: {
                headline: 'Transaction fee:',
                transactionFee: '1.5%',
                description: 'of transaction value',
                note:
                    '(with a minimum fee of USD 50 and a maximum fee of USD 1200 per transaction)'
            }
        }
    },
    purchaser: {
        seo: {
            title: 'Purchaser'
        },
        header: {
            headline: 'Unlock your potential.',
            subHeadline:
                'Benefit from a tailored tool to master the leading cost drivers in operating a ship.'
        },
        benefits: [
            {
                headline: 'Easy contract management',
                description:
                    'Upload your full contractual terms to Closelink which allows offer comparison among several ports, products and suppliers with a single mouse click. Assign each vessel individually to a specific contractual partner. Check your liftings against potential volume agreements and keep track of your suppliers’ performance. Benefit most from your contractual agreements.'
            },
            {
                headline: 'Swift creation of enquiries',
                description:
                    'Check your vessel’s demand against your lifting options based on the vessel’s service, rest-on-board volumes, your contractual terms and potential alternatives. Double check products and volumes requested by your vessel for validity and necessity. Send your enquiry to one dedicated or several suppliers in a standardized process.'
            },
            {
                headline: 'Real demand. <br /> At all times.',
                description:
                    'Keep your ROB information up-to-date, define minimum and maximum volumes per vessel tank to receive automated alerts about upcoming demand. This ensures sufficient time and options to optimize your procurement.'
            }
        ],
        challenges: [
            {
                headline: 'Receive and compare offers',
                description:
                    'Receive email updates about incoming supplier offers. Benefit from an aggregated offer view to easily compare based on costs, supply methods, notice days and potential payment terms.'
            },
            {
                headline: 'Get it approved',
                description:
                    'Benefit from our integrated approval process in line with the specifics of an order. Ask your technical inspector to confirm products and volumes or invite your accountant to confirm the order to be within budget.'
            },
            {
                headline: 'Stay in contact',
                description:
                    'Communicate with your supplier and discuss any potential details throughout the entire ordering process using our live chat function. Track any changes made to an initial enquiry.'
            },
            {
                headline: 'Get it by the numbers',
                description:
                    'Visually analyze supply data per vessels, suppliers, ports and products over any period of time. Get detailed information to set right priorities and continuously improve your operations.'
            },
            {
                headline: 'Everything under control',
                description:
                    'Create and manage different company and user hierarchies. Assign or restrict individual access. A reliable feature for large procurement teams or a set-up across several companies and offices.'
            },
            {
                headline: 'Part of your process',
                description:
                    'Closelink is fully integrable into your existing system. Our tech team is happy to provide a pre-defined API or tailor an individual solution to fit your existing technical environment.'
            }
        ]
    },
    contact: {
        seo: {
            title: 'Contact'
        },
        contactForm: {
            headline: 'Get in contact.',
            subHeadline:
                'You’ve got feedback, questions or just want to say hello? We’re here for you. Leave us a message and we’ll be right with you.'
        },
        socialMedia: {
            headline: 'Follow us and stay tuned'
        }
    },
    news: {
        seo: {
            title: 'News'
        },
        headline: 'Explore and stay informed <br /> about the latest news.'
    },
    enterTheFuture: {
        seo: {
            title: 'Enter the Future with Closelink'
        },
        headline: 'Welcome to the future of maritime procurement',
        subHeadline:
            'By using Closelink - a category management platform tailored to the sourcing and procurement in shipping.',
        benefits: {
            reduceCosts: 'Reduce vessel OpEx',
            reduceWork: 'Automate repetitive tasks',
            improveStrategy: 'Increase supply resilience'
        },
        visitAtSmm: {
            headline: 'Visit us at SMM',
            lookForward:
                'We will participate at the SMM Hamburg this year which is always a great opportunity to meet in person. In case you are at this year’s SMM, make sure to pass by. Our booth is in Hall A3, Stand 208. We are looking forward to welcoming you there.',
            improveSolutions:
                'If you are interested in a personal product demo or simply to catch-up, make sure to book a short 30mins meeting upfront.'
        },
        requestDemo: {
            headline: 'Request a demo',
            subHeadline:
                'You are not visiting SMM Hamburg or simply can’t wait? We would be happy to jump on a call with you or meet in person!'
        }
    },
    404: {
        seo: {
            title: '404 - Not found'
        },
        message: 'We couldn’t find the page you’re looking for.',
        back: 'Go to start page'
    },
    footer: {
        closelink: 'Closelink',
        purchaser: 'Purchaser',
        supplier: 'Supplier',
        about: 'About',
        company: 'Company',
        contact: 'Contact',
        career: 'Career',
        security: 'Security',
        news: 'News',
        termsAndPolicies: 'Terms & Policies',
        imprint: 'Imprint',
        termsAndConditions: 'Terms & Conditions',
        privacyPolicy: 'Privacy Policy',
        address: {
            company: 'Closelink GmbH',
            parts: ['c/o WeWork', 'Axel Springer Platz 3', '20355 Hamburg']
        }
    },
    common: {
        news: {
            headline: 'Our latest news',
            newsOverviewLinkText: 'Read more articles',
            newsPostLinkText: 'Read more'
        },
        contactForm: {
            headline: 'Any open questions?',
            subHeadline:
                'We’re ready to answer any open questions. Let’s work on tailored solutions for your procurement operations.'
        },
        quote: {
            authorNameSuffix: 'at Closelink'
        }
    }
};
