import React from 'react';
import styled from 'styled-components';
import { Box } from '../../../src/components/system';

import refactoringProcessVideoUrl from './refactoring-process.mp4';

const VideoWrapper = styled.video`
    width: 100%;
    height: auto;
`;

export const RefactoringProcessVideo = () => (
    <Box width="100%" margin="0 auto">
        <VideoWrapper autoPlay loop muted playsInline>
            <source src={refactoringProcessVideoUrl} type="video/mp4" />
        </VideoWrapper>
    </Box>
);
